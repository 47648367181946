import { asToolsApi } from "functions/src/data-models/commons/model/api.model";

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  labelsAppID: 'G3HYTNUIlx8YzxjtaYiI',
  LOCATION_API_KEY: 'ACsoGKJzbQ4W3JedkPTFN8os11iULxcs3fBb2Ung',
  VAPID_PUBLIC_KEY: 'BNSzxRyPNdSMuLBVX2JqE4_-HdnsOP0iuAgXl9oGQQ6tV42nDEjVJH1hhTNpZ_bcXISYnDqvQaEAhxA4bsTC2EU',
  asToolApi: asToolsApi.asToolApi,
  asToolApiKey: asToolsApi.asToolApiKey,
  firebase: {
    apiKey: 'AIzaSyD6hst-s8H6U15SqF7CL4-MZeSmVOkU8NA',
    authDomain: 'find-company-9c35b.firebaseapp.com',
    databaseURL: 'https://find-company-9c35b.firebaseio.com',
    projectId: 'find-company-9c35b',
    storageBucket: 'find-company-9c35b.appspot.com',
    messagingSenderId: '936918480557',
    appId: '1:936918480557:web:d5e38c2cbbbc436771b824',
    measurementId: 'G-1MZR7YEL23'
  },
  googleMapAPIKey: 'AIzaSyCdeA9MMkG2sfQebvfWZLNzJKbV4MUXX3Q',
  address: {
    api: 'https://europe-west1-v4work-prod.cloudfunctions.net/applicationApi/api/v1/address/postal-code',
    key: 'r5uAxZjEHtJcy83sX6HF2LZif3HTk3xmllfLjM2CmyV',
    secret: 'YOWjneyIb2h7s4Zf9ZqSCN42uETXIZiL4MNk0BpYK4Xr9hpYx0'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
