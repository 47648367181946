import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PostcodeAddress } from './address.model';
import { environment } from 'src/environments/environment';
import { GeoPoint } from '@models/commons';
import firestore from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(
    private http: HttpClient
  ) { }
  
  public getAddress(postalCode: string, houseNumber: number, houseNumberAddition: string = '') {
    const username = environment.address.key;
    const password = environment.address.secret;
    return this.http.post<any>(environment.address.api, { postalCode, username, houseNumber, houseNumberAddition, password }).pipe(take(1)).toPromise();
  }

  public getGeoLocation(postalcode: string, housenumber: number): Promise<GeoPoint> {
    return new Promise(async (resolve, reject) => {
      if (postalcode.length === 6 && housenumber > 0) {
        try {
          const data = await this.getAddress(postalcode, housenumber);
          if (data && data.latitude && data.longitude) {
            const geo: GeoPoint = new firestore.firestore.GeoPoint(data.latitude, data.longitude);
            resolve(geo);
          } else {
            reject('location_not_found');
          }
        } catch (error) {
          reject(error);
        }
      } else {
        reject('invalid_postal_code_or_number');
      }
    });
  }
}
