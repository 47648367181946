import { Subscription } from 'rxjs';

export class Sub {
    private subscription: Subscription;

    constructor() {
        this.subscription = new Subscription();
    }

    public unsubscribe(): void {
        this.subscription.unsubscribe();
    }

    public add(...subs: Subscription[]): void {
        subs.forEach(sub => {
            this.subscription.add(sub);
        });
    }
}
