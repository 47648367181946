import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { keys } from 'lodash';
import { ObjectMap, DirectionTypes } from '@models/commons';
import { FileService } from '@core/services/file/file.service';
import * as exifUtils from './exif.js';
import { ImageCroppedEvent } from 'ngx-image-cropper';

export interface IUploadProgress {
    message?: string;
    percent?: number;
}

export interface ImageHandlerOutput {
    file: File;
    url: string;
    id: number;
    uniqueId: string;
    errorMsg?: string;
    uploadPath?: string;
    downloadUrl?: string;
    exif?: ObjectMap<any>;
    base64?: string;
    imageQuality: number;
}

export class ImageHandlerConfig {
    isMultiple?: boolean; // if true, then the corresponding result is an array
    shouldCrop?: boolean; // defaults to true
    shouldUpload?: boolean; // defaults to true
    maintainAspectRatio?: boolean;
    aspectRatio?: number; // defaults to 1/1
    uploadFolder?: string;
    resizeWidth?: number; // in px
    resizeHeight?: number; // in px
    defaultImages?: string[];
    stripExif?: boolean;
    maxImageSize?: number;
    imageQuality?: number;

    // output configs
    outputFile?: boolean;
    outputBase64?: boolean;
    outputUploadPath?: boolean;
    outputId?: boolean;
    outputExif?: boolean;

    constructor() {
        this.shouldCrop = true;
        this.shouldUpload = true;
        this.aspectRatio = 1 / 1;
        this.uploadFolder = 'uploads/unnamed';
        this.defaultImages = [];
        this.maxImageSize = 1024 * 1000;
    }
}

@Component({
    selector: 'app-image-handler-modal',
    styleUrls: ['./image-handler-modal.component.scss'],
    templateUrl: './image-handler-modal.component.html',
})
export class ImageHandlerModalComponent implements OnInit, AfterViewInit {
    @ViewChild('imageSelectorElement', { static: false }) private imageSelector: ElementRef;

    public ImageHandlerOutputChanged: File;
    public fileHandlerCursor: number;
    public previewMode: boolean;
    public selectedFiles: File[] = [];
    public uploadProgress: ObjectMap<IUploadProgress> = {};
    public files: ImageHandlerOutput[] = [];
    public fileInScope: ImageHandlerOutput;
    public replaceFileActive: boolean;
    public busy = true;

    constructor(
        public dialogRef: MatDialogRef<ImageHandlerModalComponent>,
        @Inject(MAT_DIALOG_DATA) public config: ImageHandlerConfig,
        private fileService: FileService,
        public domSanitizationService: DomSanitizer
    ) { }

    ngOnInit() {
        // sets defaults config values
        this.config = Object.assign({}, new ImageHandlerConfig(), this.config);
    }

    async ngAfterViewInit() {
        if (this.config.defaultImages.length > 0) {
            const files: File[] = await Promise.all(this.config.defaultImages.map(async (url) => {
                try {
                    const res = url ? await this.fileService.getFileFromUrl(url) : null;
                    return res;
                } catch (e) {
                    console.log(e);
                    return null;
                }
            }));

            const validFiles = files.filter(f => !!f);

            if (validFiles.length > 0) {
                this.onFileSelected(null, files);
            } else {
                // Wait a millie tick for it to initialize
                setTimeout(() => {
                    this.imageSelector.nativeElement.click();
                }, 10);
            }
        } else {
            this.imageSelector.nativeElement.click();
        }

        setTimeout(() => {
            this.busy = false;
        }, 200);
    }

    private getImageQuality(file: File): number {
        // in order to be accurate, file size (or resize width) has to be taken into account.
        const ratio = 1; // (this.config.resizeWidth || 1000);
        const percent = this.config.imageQuality || (this.config.maxImageSize / file.size * (100 * ratio));

        return percent > 0 && percent < 100 ? percent : 100;
    }

    public async onFileSelected(evt: any, defaultFiles?: File[]) {
        if (this.replaceFileActive) {
            const files: File[] = await this.fileService.getFilesFromInput(evt);
            const file = files[0];
            this.files[this.fileInScope.id].file = file;

            this.files[this.fileInScope.id].imageQuality = this.getImageQuality(file);

            this.selectFile(this.files[this.fileInScope.id]);
        } else {
            const files: File[] = defaultFiles || await this.fileService.getFilesFromInput(evt);

            this.files = files.map((file, index) => {
                return {
                    file,
                    url: this.getUrlFromFile(file),
                    id: index,
                    imageQuality: this.getImageQuality(file),
                    uniqueId: `${Date.now()}-${Math.floor(Math.random() * 1000000)}`
                };
            });

            // we select the first file by default
            this.selectFile(this.files[0]);
        }

        this.replaceFileActive = false;
    }

    // invoked by image cropper to get updated cropped image
    public imageCropped(event: ImageCroppedEvent) {
        const base64 = event.base64;

        if (base64) {
            const file = this.fileService.base64toBlob(base64) as File;
            this.files[this.fileInScope.id].file = file;
            this.files[this.fileInScope.id].base64 = base64;
            this.files[this.fileInScope.id].url = this.getUrlFromFile(file);
        }
    }

    public convertDMSToDD(degrees: number, minutes: number, seconds: number, direction: DirectionTypes) {
        let dd = degrees + (minutes / 60) + (seconds / 3600);

        if (direction === 'S' || direction === 'W') {
            dd = dd * -1;
        }

        return dd;
    }

    public selectFile(file: ImageHandlerOutput) {
        let promise = Promise.resolve({ file: file.file, meta: {} as ObjectMap<any> });

        if (this.config.stripExif) {
            promise = this.stripImageMetadata(file.file);
        }

        promise.then((f) => {
            file.file = f.file;

            if (this.config.outputExif) {
                file.exif = f.meta;

                // latitude
                if (f.meta.GPSLatitude) {
                    file.exif.latitude = this.convertDMSToDD(
                        f.meta.GPSLatitude[0].numerator / f.meta.GPSLatitude[0].denominator,
                        f.meta.GPSLatitude[1].numerator / f.meta.GPSLatitude[1].denominator,
                        f.meta.GPSLatitude[2].numerator / f.meta.GPSLatitude[2].denominator,
                        f.meta.GPSLatitudeRef
                    );
                }

                // longitude
                if (f.meta.GPSLongitude) {
                    file.exif.longitude = this.convertDMSToDD(
                        f.meta.GPSLongitude[0].numerator / f.meta.GPSLongitude[0].denominator,
                        f.meta.GPSLongitude[1].numerator / f.meta.GPSLongitude[1].denominator,
                        f.meta.GPSLongitude[2].numerator / f.meta.GPSLongitude[2].denominator,
                        f.meta.GPSLongitudeRef
                    );
                }
            }

            file.url = this.getUrlFromFile(file.file);
            this.fileInScope = Object.assign({}, file);
        });
    }

    replaceImage(file: ImageHandlerOutput) {
        this.replaceFileActive = true;
        this.selectFile(file);
        this.imageSelector.nativeElement.click();
    }

    closeModal() {
        this.dialogRef.close();
    }

    private stripImageMetadata(file: File): Promise<{ file: File; meta: ObjectMap<any> }> {
        return new Promise(resolve => {
            const url = window.URL || window.webkitURL;
            const objURL = url.createObjectURL || false;
            const c = document.createElement('canvas');
            const cx = c.getContext('2d');

            function loadImage(fileUrl: string, meta: ObjectMap<any>) {
                const img = new Image();
                img.src = fileUrl;
                img.onload = function () {
                    imagetocanvas(this as CanvasImageSource, img.naturalWidth, img.naturalHeight, meta);
                };
            }

            function imagetocanvas(img: CanvasImageSource, w: number, h: number, meta: ObjectMap<any>) {
                c.width = w;
                c.height = h;
                cx.drawImage(img, 0, 0, w, h);
                c.toBlob(function (blob) {
                    resolve({ file: blob as File, meta });
                }, 'image/jpeg', 0.5);
            }

            exifUtils.getData(file, function () {
                const data = exifUtils.getAllTags(this);

                const metaInfo: ObjectMap<any> = {};
                for (const i in data) {
                    if (i === 'MakerNote') { continue; }
                    const disp = data[i];
                    metaInfo[i] = disp;
                }

                if (objURL) {
                    loadImage(url.createObjectURL(file), metaInfo);
                } else {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function (ev) {
                        loadImage(ev.target.result as string, metaInfo);
                    };
                }
            });
        });
    }

    loadImageFailed() {
        this.closeModal();
        alert('Image handling failed');
    }

    private getPathForUpload(uniqueId: string, filename?: string): string {
        let path = `${this.config.uploadFolder}/${uniqueId}_image_`;
        path += filename || 'random_filename.jpg';

        return path;
    }

    private updateUploadProgress(progress: number, file: ImageHandlerOutput) {
        const percent = Math.ceil(progress);
        this.uploadProgress[file.id] = {
            percent,
            message: `${percent}%`
        };
    }

    public disableBtns(): boolean {
        return keys(this.uploadProgress).length > 0;
    }

    private handUploadPromise(promise: Promise<any>, file: ImageHandlerOutput) {
        return new Promise<void>(resolve => {
            promise.then(downloadUrl => {
                file.downloadUrl = downloadUrl;
                resolve();
            }).catch(e => {
                console.log(e);
                file.errorMsg = e;
                resolve();
            });
        });
    }

    outputResult() {
        const result: ImageHandlerOutput[] | ImageHandlerOutput = this.config.isMultiple ? this.files : this.files[0];
        this.dialogRef.close(result);
    }

    public async uploadFiles() {
        if (this.files.length > 0) {
            // stops modal from being closed with backdrop
            this.dialogRef.disableClose = true;

            this.files = this.files.map(file => {
                file.uploadPath = this.getPathForUpload(file.uniqueId, file.file.name);

                return file;
            });

            // here we upload images in parallel
            await Promise.all(this.files.map(async file => {
                await this.uploadFile(file);
                return true;
            }));

            // here we output results
            this.outputResult();
        } else {
            alert('An error was encountered');
            this.closeModal();
        }
    }

    public uploadFile(file: ImageHandlerOutput) {
        if (file.base64) {
            return this.handUploadPromise(this.fileService.uploadBase64Image(
                file.base64,
                file.uploadPath,
                (progress: number) => {
                    this.updateUploadProgress(progress, file);
                }
            ), file);
        } else {
            return this.handUploadPromise(this.fileService.uploadFileImage(
                file.file,
                file.uploadPath,
                (progress: number) => {
                    this.updateUploadProgress(progress, file);
                }
            ), file);
        }
    }

    private getUrlFromFile(file: File) {
        if (file) {
            return URL.createObjectURL(file);
        } else {
            return 'https://cdn1.iconfinder.com/data/icons/image-manipulations/100/13-512.png';
        }
    }
}
