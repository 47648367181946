import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { Observable, combineLatest, of } from 'rxjs';
import { UserNoticeBoardRef, NoticeBoardItem } from '@models/commons';
import { DocItem } from '@models/commons';

@Injectable({
  providedIn: 'root'
})
export class NoticeBoardService {
  private baseRef: string;

  constructor(
    private afDb: FirestoreService,
    private localStore: LocalStorageService
  ) {
    this.baseRef = `companies/${this.localStore.getItemSync('companyId')}/services/${this.localStore.getItemSync('serviceId')}`;
    this.localStore.getItems(['companyId', 'serviceId']).subscribe(arr => {
      this.baseRef = `companies/${arr[0]}/services/${arr[1]}`;
    });
  }

  public async createNoticeBoardItem(noticeBoard: NoticeBoardItem): Promise<string> {
    noticeBoard.id = this.afDb.getNewId();

    await this.afDb.set(`${this.baseRef}/noticeBoardItems/${noticeBoard.id}`, noticeBoard as DocItem);

    return noticeBoard.id;
  }

  public deleteNoticeBoardItem(noticeBoardId: string): Promise<void> {
    return this.afDb.remove(`${this.baseRef}/noticeBoardItems/${noticeBoardId}`);
  }

  public updateNoticeBoardItem(noticeBoard: NoticeBoardItem): Promise<void> {
    return this.afDb.update(`${this.baseRef}/noticeBoardItems/${noticeBoard.id}`, noticeBoard as DocItem);
  }

  public updateNoticeBoardRef(ref: UserNoticeBoardRef): Promise<void> {
    const unseenNoticeBoardItems = ref.items.reduce((prev, cur) => {
      return prev + (cur.seen || cur.id === this.localStore.getItemSync('userId') ? 0 : 1);
    }, 0);

    ref.unseen = unseenNoticeBoardItems > 0;
    return this.afDb.update(`${this.baseRef}/userNoticeBoards/${ref.id}`, ref as DocItem);
  }

  public getUserNoticeBoardRefByUserId(userId?: string): Observable<UserNoticeBoardRef> {
    if (!userId) {
      userId = this.localStore.getItemSync('userId');
    }

    return this.afDb.docWithId$(`${this.baseRef}/userNoticeBoards/${userId}`);
  }

  public getNoticeBoardItemById(id: string): Observable<NoticeBoardItem> {
    return this.afDb.docWithId$(`${this.baseRef}/noticeBoardItems/${id}`);
  }

  public getNoticeBoardItemsForUser(userId?: string): Observable<NoticeBoardItem[]> {
    if (!userId) {
      userId = this.localStore.getItemSync('userId');
    }

    return this.afDb.colWithIds$(`${this.baseRef}/noticeBoardItems`, ref => {
      return ref.where('userId', '==', userId).orderBy('log.createdOn', 'desc');
    }) as Observable<NoticeBoardItem[]>
  }

  public getNoticeBoardItemsByIds(ids: string[]): Observable<NoticeBoardItem[]> {
    return ids.length === 0 ? of([]) : combineLatest(ids.map(id => {
      return this.getNoticeBoardItemById(id);
    }));
  }

  public getNoticeBoardItemsCuntByEventId(eventId: string) {
    return this.afDb.getCounts(`${this.baseRef}/noticeBoardItems`, () => [['eventId', '==', eventId]]);
  }

  public getNoticeBoardItems() {
    return this.afDb.colWithIds$(`${this.baseRef}/noticeBoardItems`) as Observable<NoticeBoardItem[]>;
  }
}
